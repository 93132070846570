import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import NavigationLinks from './NavigationLinks';
import NavigationLinkWrap from './NavigationLinkWrap';
import NavigationBar from './NavigationBar';
import MegaMenu from '../../common/mega-menu/MegaMenu';

import Logo from '../logo/Logo';
import { Cell, Grid } from '../grid';
import HamburgerToggle from '../../common/burger/HamburgerToggle';
import SearchOverlay from '../../organisms/search-component/SearchComponent';
import Container from '../container/Container';
import MobileSearch from '../../common/mobile-search';
import config from '../../../utils/config';

const StyledNavigation = styled.nav`
  position: sticky;
  width: 100%;
  z-index: 5000;
  top: 0;
  margin: 0 auto;
  padding: 16px;
  transition: background-color 300ms ease-out;

  ${props => props.isBlue && `background-color: #00254A;`}

  ${props =>
    props.isSticky &&
    `
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(14,30,37,.12);
    transition: background-color 300ms ease-out;
  `};

  @media print {
    position: relative;
    box-shadow: none;
    padding-left: 24px;
  }

  .Navigation__Search-Icon-Container {
    position: relative;
    outline: none;

    .Navigation__Search-Icon {
      color: #ffffff;
      font-size: 20px;
      position: relative;
      left: -8px;
      cursor: pointer;
    }
  }

  .NavigationBar__Mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const LogoPrintWrap = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

const LogoWrap = styled.div`
  @media print {
    display: none;
  }
`;

const NavigationMobile = styled.a`
  padding: 16px;
  display: block;
  position: absolute;
  right: 0;
  bottom: -4px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

class Navigation extends Component {
  state = {
    searchBarActive: false,
  };

  toggleSearchBar = () => {
    this.setState(
      prevState => ({
        searchBarActive: !prevState.searchBarActive,
      }),
      () => {
        const { searchBarActive } = this.state;
        if (searchBarActive) {
          document.querySelector('body').style.overflow = 'hidden';
        } else {
          document.querySelector('body').style.overflow = '';
        }
      }
    );
  };

  render() {
    const {
      toggleMegaMenu,
      isActive,
      isStaffPage,
      isSticky,
      isBlue,
    } = this.props;
    const { searchBarActive } = this.state;

    return (
      <StyledNavigation
        isStaffPage={isStaffPage}
        isSticky={isSticky}
        isBlue={isBlue}
      >
        <Container noSpacing>
          <Grid>
            <Cell>
              {searchBarActive && (
                <SearchOverlay closeSearch={this.toggleSearchBar} />
              )}
            </Cell>

            <Cell
              cellSize={{
                phone: 3,
                tablet: 2,
                desktop: 2,
                widescreen: 2,
              }}
              cellAlign="center"
            >
              <Link to="/">
                <LogoWrap>
                  <Logo isBlue={isSticky} />
                </LogoWrap>

                <LogoPrintWrap>
                  <Logo isBlue />
                </LogoPrintWrap>
              </Link>
            </Cell>
            <Cell
              cellSize={{
                phone: 3,
                tablet: 10,
                desktop: 10,
                widescreen: 10,
              }}
            >
              <NavigationBar>
                <NavigationLinkWrap>
                  <NavigationLinks
                    toggleMegaMenu={toggleMegaMenu}
                    toggleSearchBar={this.toggleSearchBar}
                  />
                </NavigationLinkWrap>
                <div className="NavigationBar__Mobile">
                  <MobileSearch
                    handleClick={() => this.setState({ searchBarActive: true })}
                    isSticky={isSticky}
                  />
                  <HamburgerToggle
                    clickHandler={() => toggleMegaMenu()}
                    isActive={isActive}
                    isSticky={isSticky}
                  />
                </div>
              </NavigationBar>
              <NavigationMobile href={`tel:${config.tel}`}>
                Tel: {config.tel}
              </NavigationMobile>
            </Cell>
          </Grid>
        </Container>

        {isActive && (
          <MegaMenu
            isActive={isActive}
            isSticky={isSticky}
            toggleMegaMenu={toggleMegaMenu}
          />
        )}
      </StyledNavigation>
    );
  }
}

Navigation.propTypes = {
  toggleMegaMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

Navigation.defaultProps = {
  isBlue: false,
};

export default Navigation;
