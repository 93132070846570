import React, { useState } from 'react';
import { Link } from 'gatsby';

import StyledFooter from './StyledFooter';
import Container from '../../global/container/Container';
import RightList from '../list/RightList';
import InputGroup from '../input-group/InputGroup';
import { Cell, Grid } from '../../global/grid';

import common from '../../../helpers/common';
import StyledFooterShareButtons from '../../atoms/share-buttons/StyledFooterShareButtons';

/**
 * Small helper function to return the current year
 * This is to ensure the date is always up-to-date at the
 * time of deployment.
 */
const getCurrentYear = () => {
  const dt = new Date();
  return dt.getFullYear();
};

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Grid>
          <Cell cellSize={{ desktop: 7, widescreen: 7 }}>
            <div style={{ maxWidth: 275, maxHeight: 163 }}>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '59.1%',
                  height: 'auto',
                  overflow: 'hidden',
                  marginBottom: 30,
                }}
              >
                <iframe
                  frameBorder="0"
                  scrolling="no"
                  allowtransparency="true"
                  title="SRA Logo"
                  src="https://cdn.yoshki.com/iframe/55845r.html"
                  style={{
                    border: 0,
                    margin: 0,
                    padding: 0,
                    backgroundColor: 'transparent',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                  }}
                />
              </div>
            </div>

            <div className="Footer__Contact">
              <p className="Footer__Contact-Address">{common.contact.postal}</p>
              <a href="tel: 01325 341 500" className="Footer__Contact-Tel">
                {common.contact.number}
              </a>
            </div>
          </Cell>

          <Cell cellSize={{ desktop: 5, widescreen: 5 }}>
            <ul className="Footer__Links Footer__Links--Main">
              <li>
                <Link to="/covid-19">Covid-19</Link>
              </li>
              <li>
                <Link to="/our-services/you-and-your-family">
                  You &amp; your family
                </Link>
              </li>
              <li>
                <Link to="/our-services/your-home">Your home</Link>
              </li>
              <li>
                <Link to="/our-services/farms-estates">
                  Farms &amp; estates
                </Link>
              </li>
              <li>
                <Link to="/our-services/business-commercial">
                  Business &amp; commercial
                </Link>
              </li>
              <li>
                <Link to="/news">News &amp; insights</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </Cell>

          <Cell cellSize={{ tablet: 6, desktop: 8, widescreen: 8 }}>
            <ul className="Footer__Links Footer__Links--Inline">
              <li>
                <Link to="/sitemap">SITEMAP</Link>
              </li>
              <li>
                <Link to="/legal-regulatory">
                  LEGAL, REGULATORY &amp; COMPLAINTS HANDLING
                </Link>
              </li>
              <li>
                <Link to="/our-charges">OUR CHARGES</Link>
              </li>
              <li>
                <Link to="/who-we-are/careers">CAREERS</Link>
              </li>
            </ul>
          </Cell>

          <Cell cellSize={{ tablet: 6, desktop: 4, widescreen: 4 }}>
            <StyledFooterShareButtons>
              <RightList spaceValue={15}>
                <li>
                  <a
                    aria-hidden="true"
                    aria-label="Share on linkedIn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/latimer-hinks-solicitors/"
                  >
                    <i className="Footer__Icon icon-linked_in" />
                  </a>
                </li>
                <li>
                  <a
                    aria-hidden="true"
                    aria-label="Share on Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/LatimerHinks"
                  >
                    <i className="Footer__Icon icon-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    aria-hidden="true"
                    aria-label="Share on Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/latimerhinkssolicitors/"
                  >
                    <i className="Footer__Icon icon-facebook" />
                  </a>
                </li>
              </RightList>
            </StyledFooterShareButtons>
          </Cell>
        </Grid>

        <hr className="Footer__Divider" />

        <small className="Footer__SmallPrint Footer__SmallPrint--Copyright">
          &copy; Copyright Latimer Hinks {getCurrentYear()}. All Rights
          Reserved.
        </small>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
