import styled from 'styled-components';

const StyledMegaMenu = styled.div`
  position: absolute;
  transform: translateX(-50%);
  max-width: 1480px;
  width: 100%;
  left: 50%;
  top: ${props => (props.isSticky ? '88px' : '92px')};
  @media screen and (min-width: 1025px) {
    top: ${props => (props.isSticky ? '132px' : '108px')};
  }
  .MegaMenu__Inner {
    background-color: white;
    padding: 48px 40px 24px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    
    ${props => props.isActive && `opacity: 1;`}
    
    @media screen and (max-width: 1024px) {
      display: none;
      position: fixed;
      height: calc(100vh - 100px);
      width: 100vw;
      padding: 0px 16px 140px;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      ${props => props.isActive && 'display: block;'};
    }

    @media screen and (min-width: 1025px) {
      &::before {
        content: '';
        position: relative;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid white;
        top: -88px;
        left: 54.5%;
      }
    }
  }

  .MegaMenu__Heading {
    color: #939598;
    font-size: 34px;
    letter-spacing: -1px;
    font-weight: 200;
    margin-top: -40px;
  }

  .MegaMenu__Heading--Mobile {
    display: block;
    margin-top: 0;
    padding-top: 16px;

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  .MegaMenu__Blurb {
    color: #000000;
    font-size: 18px;
  }

  .MegaMenu__Thumbnail {
    margin-bottom: 16px;
    max-width: 100%;

    @media screen and (max-width: 600px) {
      display: none;
    }

    @media screen and (min-width: 601px) and (max-width: 1024px) {
      float: left;
      max-width: 250px;
      margin-right: 24px;
    }
  }

  .MegaMenu__List {
    margin-top: 10px;
    list-style-type: none;
    padding-left: 0;

    li {
      margin-bottom: 16px;
      font-size: 24px;
    }

    li a {
      color: #6D6E71;
      text-decoration: none;

      &:hover {
        color: #f58021;
      }

      @media screen and (min-width: 1024px) {
        &::after {
          content: '>';
          float: right;
          position: relative;
          right: 48px;
        }
      }
    }
  }

  .MegaMenu__List--Mobile {
    &::before {
      content: '';
      height: 3px;
      width: 100%;
      background-color: #eaeaea;
      display: block;
      margin-bottom: 24px;
    }

    li:hover:before {
      color: #f58021;
    }

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  .MegaMenu__Links--Mobile {
    @media screen and (max-width: 1024px) {
      li::before {
        content: '>';
        display: inline-block;
        color: #6d6e71;
        margin-right: 8px;
      }
    }
  }

  .MegaMenu__List--Small {
    &::before {
      content: '';
      height: 100%;
      position: absolute;
      width: 2px;
      background-color: #ededed;
      top: 45px;
      height: calc(100% - 90px);
      margin-left: -48px;
    }

    li {
      font-size: 16px;

      a::after {
        content: '';
      }
    }
  }

  .MegaMenu__ListHeading {
    font-weight: 600;
    color: #6d6e71;
`;

export default StyledMegaMenu;
