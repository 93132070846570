import React from 'react';
import styled from 'styled-components';

const StyledMobileSearch = styled.div`
  margin-right: 12px;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  .icon-search {
    color: ${props => (props.isSticky ? '#00254A' : '#ffffff')};
    cursor: pointer;
  }
`;

export default function MobileSearch({ handleClick, isSticky }) {
  return (
    <StyledMobileSearch onClick={handleClick} isSticky={isSticky}>
      <i className="icon-search" />
    </StyledMobileSearch>
  );
}
