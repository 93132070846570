import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../images/latimer_hinks_logo.svg';
import logoBlue from '../../../images/latimer_hinks_logo_blue.svg';

const Logo = ({ isBlue = false }) => (
  <img src={isBlue ? logoBlue : logo} alt="Latimer Hinks Logo" width={144} />
);

Logo.propTypes = {
  isBlue: PropTypes.bool.isRequired,
};

export default Logo;
