import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 16px;

  @media print {
    display: none;
  }

  .ProfessionalAccreditations__Entry {
    width: 40vw;
    text-align: center;

    @media screen and (min-width: 768px) {
      width: 25vw;
    }

    @media screen and (min-width: 1024px) {
      width: auto;
      text-align: left;
    }
  }

  .ProfessionalAccreditations__Logo {
    margin-bottom: 20px;
    height: 40px;
  }

  .ProfessionalAccreditations__Member {
    font-size: 12px;
    padding-bottom: 8px;

    a {
      color: #939598;
      &:hover {
        color: #f58021;
      }
    }

    @media screen and (min-width: 768px) {
      font-size: 14px;
      padding-bottom: 16px;
    }
  }
`;
