const getSubServices = (services, parentId) => {
  const matchedServices = services.filter(page => {
    if (page.node) {
      return page.node.parentId === parentId;
    }

    if (page.wpParent) {
      return page.wpParent.node.id === parentId;
    }

    return [];
  });

  const group = {
    law: [],
    property: [],
  };

  matchedServices
    .filter(page => {
      if (page.node) {
        return page.node.serviceAcf && page.node.serviceAcf.type !== 'n-a';
      }

      return page.serviceAcf && page.serviceAcf.type !== 'n-a';
    })
    .forEach(page => {
      if (page.node) {
        if (page.node.serviceAcf.type === 'commercial-property') {
          group.property.push(page);
        } else {
          group.law.push(page);
        }
      } else {
        if (page.serviceAcf.type === 'commercial-property') {
          group.property.push(page);
        } else {
          group.law.push(page);
        }
      }

      return group;
    });

  const { law: commercialLaw, property: commercialProperty } = group;

  if (commercialLaw.length > 0 || commercialProperty.length > 0) {
    return group;
  }

  return matchedServices;
};

export default getSubServices;
