import React, { Component } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';

import Container from '../../global/container/Container';
import SearchWrapper from './styles/StyledSearchOverlayWrapper';
import SearchComponent from './styles/StyledSearchComponent';
import SearchResult from './styles/StyledSearchResults';
import BlockList from '../../common/list/BlockList';
import Spinner from '../../common/spinner/Spinner';

class Search extends Component {
  state = {
    pages: [],
    posts: [],
    staff: [],
    loading: false,
  };

  delayedCallback = debounce(e => {
    const getLocation = href => {
      const match = href.match(
        /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
      );

      return (
        match && {
          href,
          pathname: match[5],
        }
      );
    };
    if (e.target.value === '') {
      this.setState({
        posts: [],
        pages: [],
        staff: [],
        loading: false,
      });
    } else {
      const getAllContent = async () => {
        const base = `${process.env.GATSBY_WP_PROTOCOL}://${process.env.GATSBY_WP_BASE_URL}/wp-json/wp/v2`;
        const urls = [
          `${base}/posts?search=${e.target.value}`,
          `${base}/pages?search=${e.target.value}`,
          `${base}/staff?search=${e.target.value}`,
        ];

        try {
          const results = await Promise.all(
            urls.map(url => fetch(url).then(response => response.json()))
          );
          return results;
        } catch (error) {
          throw new Error('There was an error retrieving results', error);
        }
      };

      const writeResultsToState = async () => {
        const searchResults = await getAllContent();

        this.setState({
          posts: searchResults[0].map(post => ({
            title: post.title.rendered,
            excerpt: post.excerpt.rendered,
            slug: post.slug,
          })),
          pages: searchResults[1].map(page => ({
            title: page.title.rendered,
            overview: page.acf.overview_foreword,
            link: getLocation(page.link),
          })),
          staff: searchResults[2].map(staff => ({
            title: staff.title.rendered,
            overview: staff.acf.profile_bio,
            link: getLocation(staff.link),
            slug: staff.slug,
          })),
          loading: false,
        });
      };
      writeResultsToState();
    }
  }, 1000);

  searchWordpress = e => {
    e.persist();
    this.setState(
      {
        loading: true,
      },
      () => this.delayedCallback(e)
    );
  };

  render() {
    const { closeSearch } = this.props;
    const { pages, posts, staff, loading } = this.state;

    return (
      <SearchWrapper>
        <Container>
          <div className="SearchWrapper__Inner">
            <button
              onClick={() => closeSearch()}
              className="SearchComponent__Exit"
              type="button"
            >
              X
            </button>
            <SearchComponent onSubmit={e => e.preventDefault()}>
              <label htmlFor="searchInput">
                <i className="SearchComponent__Icon icon-search" />
                <input
                  onChange={this.searchWordpress}
                  id="searchInput"
                  className="SearchComponent__Term"
                  type="text"
                  placeholder="What are you looking for?"
                />
              </label>
              {loading && (
                <div className="SearchComponent__Spinner">
                  <Spinner />
                </div>
              )}
            </SearchComponent>
            <SearchResult>
              {posts.length > 0 && (
                <p className="SearchResults__Heading">News &amp; insights</p>
              )}
              <BlockList>
                {posts.map((post, key) => (
                  <li key={key}>
                    <Link
                      to={`/news/${post.slug}`}
                      className="SearchResults__Subheading"
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <div
                      className="SearchResults__Content"
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                  </li>
                ))}
              </BlockList>
              {pages.length > 0 && (
                <p className="SearchResults__Heading">Pages</p>
              )}
              <BlockList>
                {pages.map((page, key) => (
                  <li key={key}>
                    <Link
                      to={page.link.pathname}
                      className="SearchResults__Subheading"
                      dangerouslySetInnerHTML={{ __html: page.title }}
                    />
                    <div
                      className="SearchResults__Content"
                      dangerouslySetInnerHTML={{ __html: page.overview }}
                    />
                  </li>
                ))}
              </BlockList>
              {staff.length > 0 && (
                <p className="SearchResults__Heading">Staff Members</p>
              )}
              <BlockList>
                {staff.map((page, key) => (
                  <li key={key}>
                    <Link
                      to={`/who-we-are/our-people/${page.slug}`}
                      className="SearchResults__Subheading"
                      dangerouslySetInnerHTML={{ __html: page.title }}
                    />
                    <div
                      className="SearchResults__Content"
                      dangerouslySetInnerHTML={{ __html: page.overview }}
                    />
                  </li>
                ))}
              </BlockList>
            </SearchResult>
          </div>
        </Container>
      </SearchWrapper>
    );
  }
}

Search.propTypes = {
  closeSearch: PropTypes.func.isRequired,
};

export default Search;
