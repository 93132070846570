import styled from 'styled-components';

import { columns, viewports } from '../grid-config';

const calculateOffset = (offset, col, media) => {
  if (offset && offset[media]) {
    return `${(offset[media] / col[media]) * 100}%`;
  }
  return 0;
};

const setOrder = (order, media) => {
  if (order && order[media]) {
    return order[media];
  }
  return 0;
};

const setWidth = (size, col, media) => {
  if (size && size[media]) {
    return `${(size[media] / col[media]) * 100}%`;
  }
  return '100%';
};

const StyledCell = styled.div`
  margin-left: ${({ cellOffset }) =>
    calculateOffset(cellOffset, columns, 'phone')};
  order: ${({ cellOrder }) => setOrder(cellOrder, 'phone')};
  width: ${({ cellSize }) => setWidth(cellSize, columns, 'phone')};

  ${({ hideCell }) =>
    hideCell && hideCell.phone ? 'display: none;' : 'display: block;'}

  /**
   * Handle Cell Alignment
   * Can be one of the following options:
   * flex-start, center, flex-end, stretch
   */
  ${({ cellAlign }) => cellAlign && `align-self: ${cellAlign}`};

  @media screen and (min-width: ${viewports.tablet}) {
    margin-left: ${({ cellOffset }) =>
      calculateOffset(cellOffset, columns, 'tablet')};
    order: ${({ cellOrder }) => setOrder(cellOrder, 'tablet')};
    width: ${({ cellSize }) => setWidth(cellSize, columns, 'tablet')};

    ${({ hideCell }) =>
      hideCell && hideCell.tablet ? 'display: none;' : 'display: block;'}
  }

  @media screen and (min-width: ${viewports.desktop}) {
    margin-left: ${({ cellOffset }) =>
      calculateOffset(cellOffset, columns, 'desktop')};
    order: ${({ cellOrder }) => setOrder(cellOrder, 'desktop')};
    width: ${({ cellSize }) => setWidth(cellSize, columns, 'desktop')};

    ${({ hideCell }) =>
      hideCell && hideCell.desktop ? 'display: none;' : 'display: block;'}
  }

  @media screen and (min-width: ${viewports.widescreen}) {
    margin-left: ${({ cellOffset }) =>
      calculateOffset(cellOffset, columns, 'widescreen')};
    order: ${({ cellOrder }) => setOrder(cellOrder, 'widescreen')};
    width: ${({ cellSize }) => setWidth(cellSize, columns, 'widescreen')};

    ${({ hideCell }) =>
      hideCell && hideCell.widescreen ? 'display: none;' : 'display: block;'}
  }

  @media print {
    width: ${({ cellSize }) => setWidth(cellSize, columns, 'widescreen')};
    margin-left: ${({ cellOffset }) =>
      calculateOffset(cellOffset, columns, 'widescreen')};
  }

  @media print {
    ${({ cellSize }) => cellSize.print && `width: 100%;`}
  }
`;

export default StyledCell;
