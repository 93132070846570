import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMegaMenuOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: ${props => (props.opacity ? 1 : 0)};
  z-index: ${props => (props.opacity ? 50 : '-1')};
  transition: all 200ms ease-in-out;
`;

const MegaMenuOverlay = ({ opacity, toggleMegaMenu }) => (
  <StyledMegaMenuOverlay onClick={() => toggleMegaMenu()} opacity={opacity} />
);

MegaMenuOverlay.propTypes = {
  toggleMegaMenu: PropTypes.func.isRequired,
  opacity: PropTypes.string.isRequired,
};

export default MegaMenuOverlay;
