import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => (props.isExtended ? '1780' : '1480')}px;
  margin-bottom: ${props => props.verticalGap}px;
  margin-top: ${props => props.verticalGap}px;
  width: 100%;

  @media screen and (max-width: 1540px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media print {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${props =>
    props.noSpacing &&
    'padding-left: 0 !important; padding-right: 0 !important;'}
`;

const Container = ({
  children,
  verticalGap,
  isExtended,
  isFullWidth,
  noSpacing,
}) => (
  <StyledContainer
    verticalGap={verticalGap}
    isExtended={isExtended}
    isFullWidth={isFullWidth}
    noSpacing={noSpacing}
  >
    {children}
  </StyledContainer>
);

Container.defaultProps = {
  isExtended: false,
  isFullWidth: false,
  verticalGap: 0,
  noSpacing: false,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  isExtended: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  noSpacing: PropTypes.bool,
  verticalGap: PropTypes.number,
};

export default Container;
