/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import CookieBanner from './global/cookie-banner';
import Footer from './common/footer/Footer';
import MegaMenuOverlay from './common/mega-menu/MegaMenuOverlay';
import Navigation from './global/navigation/Navigation';
import ProfessionalAccreditations from './organisms/professional-accreditations/ProfessionalAccreditations';

import '../styles/latimer-hinks.scss';

const Layout = ({ children, isBlue = false, seo = undefined, path = '/' }) => {
  const [isActive, setIsActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [allowCookies, setAllowCookies] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', scrollListener);

      if (document.querySelector('body').style.overflow !== '') {
        document.querySelector('body').style.overflow = '';
      }

      if (!localStorage.getItem('accept-cookies')) {
        setAllowCookies(false);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', scrollListener);
      }
    };
  }, []);

  // React to the active flag change
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isActive) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = '';
      }
    }
  }, [isActive]);

  const scrollListener = () => {
    if (typeof window !== 'undefined') {
      if (window.pageYOffset > 90) {
        if (!isSticky) {
          setIsSticky(true);
        }
      } else {
        setIsSticky(false);
      }
    }
  };

  // Toggle active flag
  const toggleActiveFlag = () => {
    setIsActive(!isActive);
  };

  const changeCookieState = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('accept-cookies', true);
      setAllowCookies(true);
    }
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{seo ? seo.title : 'Latimer Hinks Solicitors'}</title>
        <meta
          name="description"
          content={
            seo
              ? seo.metaDesc
              : 'Based in Darlington, we are a discreet niche law firm with a national presence providing specialist and bespoke services in private client, property and agricultural law for more than 125 years.'
          }
        />

        {seo && seo.metaKeywords && (
          <meta name="keywords" content={seo.metaKeywords} />
        )}

        <meta
          property="og:title"
          content={seo ? seo.opengraphTitle : 'Latimer Hinks Solicitors'}
        />
        <meta
          property="og:description"
          content={
            seo
              ? seo.opengraphDescription
              : 'Based in Darlington, we are a discreet niche law firm with a national presence providing specialist and bespoke services in private client, property and agricultural law for more than 125 years.'
          }
        />
        <meta
          property="og:image"
          content={
            seo
              ? seo.opengraphImage?.sourceUrl
              : 'https://latimer-hinks.the-staging.co.uk/wp-content/uploads/2021/06/Latimer-Hinks-Social-Logo.png'
          }
        />

        <meta
          property="twitter:title"
          content={seo ? seo.twitterTitle : 'Latimer Hinks Solicitors'}
        />
        <meta
          property="twitter:description"
          content={
            seo
              ? seo.twitterDescription
              : 'Based in Darlington, we are a discreet niche law firm with a national presence providing specialist and bespoke services in private client, property and agricultural law for more than 125 years.'
          }
        />
        <meta
          property="twitter:image"
          content={
            seo
              ? seo.twitterImage?.sourceUrl
              : 'https://latimer-hinks.the-staging.co.uk/wp-content/uploads/2021/06/Latimer-Hinks-Social-Logo.png'
          }
        />

        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />

        <link rel="canonical" href={`https://www.latimerhinks.co.uk${path}`} />
      </Helmet>

      <Navigation
        toggleMegaMenu={() => toggleActiveFlag()}
        isActive={isActive}
        isSticky={isSticky}
        isBlue={isBlue}
      />

      <main>{children}</main>

      <Footer />
      <ProfessionalAccreditations />

      <MegaMenuOverlay opacity={isActive} toggleMegaMenu={toggleActiveFlag} />

      {!allowCookies && <CookieBanner handleClick={changeCookieState} />}
    </>
  );
};

export default Layout;
