import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './hamburgers.css';

const StyledButton = styled.button`
  @media screen and (min-width: 1025px) {
    display: none !important;
  }

  &.hamburger {
    padding: 0;
    outline: none;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: ${props => (props.isSticky ? '#00254A' : '#ffffff')};
  }

  &.hamburger.is-active {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: ${props => (props.isSticky ? '#00254A' : '#ffffff')};
    }
  }
`;

const HamburgerToggle = ({ isActive, clickHandler, isSticky }) => (
  <StyledButton
    aria-label="hamburger"
    className={classnames('hamburger hamburger--slider', {
      'is-active': isActive,
    })}
    type="button"
    isSticky={isSticky}
    onClick={clickHandler}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </StyledButton>
);

HamburgerToggle.defaultProps = {
  isActive: false,
  isSticky: false,
};

HamburgerToggle.propTypes = {
  isActive: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
  isSticky: PropTypes.bool,
};

export default HamburgerToggle;
