import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const options = {
  size: {
    small: '0',
    medium: '8',
    large: '16',
  },
};

const StyledList = styled.ul`
  list-style-type: ${props => (props.listStyled ? 'auto' : 'none')};
  padding-left: ${props => (props.listStyled ? 'auto' : 0)};
  
  li {
    margin-bottom: ${props => options.size[props.size]}px;
  }
`;

const BlockList = ({ children, size, listStyled }) => (
  <StyledList size={size} listStyled={listStyled}>
    {children}
  </StyledList>
);

BlockList.defaultProps = {
  size: 'small',
  listStyled: false,
};

BlockList.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  listStyled: PropTypes.bool,
};

export default BlockList;
