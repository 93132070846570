import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledCookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #00254a;
  width: 100vw;
  color: #ffffff;
  padding: 24px 32px;
  z-index: 100;
  text-align: center;
  line-height: 24px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.5);

  p {
    margin-bottom: 16px;
  }
`;

const CookieButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #00254a;
  color: #00254a;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0.385rem 0.75rem;
  line-height: 1.5;
  text-align: center;
  appearance: none;
`;

const CookieLink = styled(Link)`
  color: #ffffff;
  text-decoration: underline;

  &:hover {
    color: #ffffff;
    opacity: 0.8;
    text-decoration: underline;
  }
`;

export default function CookieBanner({ handleClick }) {
  return (
    <StyledCookieBanner>
      <p>
        We use cookies to improve your experience of our site (we do not track
        your identity). To comply with the new e-Privacy Directive we need to
        seek your consent to set these cookies. If you do not make a selection,
        we will assume that you consent to the cookies being set.{' '}
        <CookieLink to="/legal-regulatory">Click here to learn more</CookieLink>
        .
      </p>
      <CookieButton onClick={handleClick}>ACCEPT</CookieButton>
    </StyledCookieBanner>
  );
}
