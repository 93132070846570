import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import StyledMegaMenu from './StyledMegaMenu';
import MegaMenuContent from './MegaMenuContent';
import { Close } from '../../icons/Close';

const StyledClose = styled.span`
  color: #f58021;
  position: absolute;
  top: 20px;
  right: 24px;
  cursor: pointer;
  display: none;
  &:hover {
    color: #d9670a;
  }
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const MegaMenu = ({ isActive, isSticky, toggleMegaMenu }) => {
  // Get the services and sub-services from mesh
  const data = useStaticQuery(graphql`
    query {
      services: allWpService(
        filter: {
          slug: {
            in: [
              "your-home"
              "you-and-your-family"
              "farms-estates"
              "business-commercial"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            title
            link
            menuOrder
            slug
          }
        }
      }
      subServices: allWpService(sort: { fields: [menuOrder], order: ASC }) {
        edges {
          node {
            id
            title
            link
            menuOrder
            slug
            parentId
            serviceAcf {
              type
            }
            wpParent {
              node {
                ... on WpService {
                  id
                  title
                  slug
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledMegaMenu isActive={isActive} isSticky={isSticky}>
      <MegaMenuContent data={data} />
      <StyledClose role="button" onClick={toggleMegaMenu}>
        <Close />
      </StyledClose>
    </StyledMegaMenu>
  );
};

export default MegaMenu;
