import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../button/Button';
import InputField from '../text-field/InputField';

const StyledInputGroup = styled.div`
  .InputGroup__Container {
    display: flex;
    align-items: flex-end;
  }

  .InputGroup__Input {
    flex-grow: 3;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .InputField__Label {
      margin-bottom: 0;
    }

    .InputField__Label-Text {
      font-size: 18px;
      margin-bottom: 16px;
      padding: 0;
      width: 120%;
      @media screen and (min-width: 768px) {
        font-size: 24px;
        margin-bottom: 32px;
        width: inherit;
      }
    }
  }

  .InputGroup__Submit {
    flex-grow: 1;
  }
`;

const InputGroup = ({
  inputLabel,
  buttonLabel,
  inputName,
  inputId,
  inputPlaceholder,
  onChange,
}) => (
  <StyledInputGroup>
    <div className="InputGroup__Container">
      <div className="InputGroup__Input">
        <InputField
          label={inputLabel}
          name={inputName}
          id={inputId}
          placeholder={inputPlaceholder}
          onChange={onChange}
        />
      </div>
      <div className="InputGroup__Submit">
        <Button type="submit" label={buttonLabel} />
      </div>
    </div>
  </StyledInputGroup>
);

InputGroup.defaultProps = {
  buttonLabel: 'Submit',
  onChange: null,
};

InputGroup.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputGroup;
