/**
 * A list that displays items as inline items and the space between
 * each list item is evenly separated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledList = styled.ul`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    text-align: ${props => props.alignment};
    width: 100%;
  }
`;

const EvenList = ({
  alignment,
  children,
}) => <StyledList alignment={alignment}>{children}</StyledList>;

EvenList.defaultProps = {
  alignment: 'left',
};

EvenList.propTypes = {
  alignment: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default EvenList;
