/**
 * <Grid />
 * This component is the parent of the <Cell> components.
 * Think of this as the row of a typical grid system.
 */
import React from 'react';
import PropTypes from 'prop-types';

import StyledGrid from './styles/StyledGrid';

const Grid = ({
  children,
  spacing,
  gutter,
  alignItems,
}) => (
  <StyledGrid gutter={gutter} spacing={spacing} alignItems={alignItems}>{children}</StyledGrid>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
  gutter: PropTypes.number,
  alignItems: PropTypes.string,
};

Grid.defaultProps = {
  alignItems: 'stretch',
  spacing: null,
  gutter: 0,
};

export default Grid;
