const config = {
  addressLine1: '5-8 Priestgate',
  town: 'Darlington',
  postcode: 'DL1 1NL',
  tel: '01325 341 500',
  fax: '01325 381 072',
  email: 'lh@latimerhinks.co.uk',
};

export default config;
