import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const theme = {
  default: {
    backgroundColour: '#ffffff',
    borderColour: '#ffffff',
    height: 'calc(2.24rem + 3px)',
  },

  contactForm: {
    backgroundColour: 'rgba(0, 0, 0, 0.04)',
    borderColour: '#707070',
    height: '48px',
  },
};

const StyledInputField = styled.div`
  .InputField__Input {
    appearance: none;
    background: ${props => theme[props.variation].backgroundColour};
    border: 1px solid ${props => theme[props.variation].borderColour};
    border-radius: 0;
    width: 100%;
    display: block;
    height: ${props => theme[props.variation].height};
    line-height: 1.5;
    background-clip: border-box;
    padding: 0.375rem 0.75rem;
    outline: none;
    transition: 300ms border ease-out;
  }

  .has-error {
    border: 1px solid red;
  }

  .InputField__Input:focus {
    border: 1px solid #f58021;
    transition: 300ms border ease-out;
  }

  .InputField__Label,
  .InputField__Label-Text {
    display: block;
  }

  .InputField__Label {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .InputField__Error {
    color: red;
  }
`;

const InputField = ({
  name,
  id,
  autoComplete,
  onChange,
  label,
  placeholder,
  variation,
  type,
  isRequired,
}) => (
  <StyledInputField variation={variation}>
    <label className="InputField__Label" htmlFor={id}>
      <span className="InputField__Label-Text">{label}</span>
      <input
        className="InputField__Input validate-input-js"
        type={type}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete={autoComplete}
        name={name}
        required={isRequired}
      />
      <div className="invalid-feedback InputField__Error" />
    </label>
  </StyledInputField>
);

InputField.defaultProps = {
  autoComplete: null,
  onChange: null,
  type: 'text',
  variation: 'default',
  label: null,
  isRequired: false,
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  variation: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default InputField;
