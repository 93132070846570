import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import EvenList from '../../common/list/EvenList';

const NavigationLinks = ({ toggleMegaMenu, toggleSearchBar }) => (
  <EvenList alignment="center">
    <li>
      <Link to="/covid-19">Covid-19</Link>
    </li>
    <li>
      <Link to="/who-we-are">Who we are</Link>
    </li>
    <li>
      <span
        className="Navigation__Faux-Link"
        onClick={() => toggleMegaMenu()}
        role="button"
        tabIndex="0"
        onKeyDown={() => {}}
      >
        Our services
      </span>
    </li>
    <li>
      <Link to="/news">News &amp; insights</Link>
    </li>
    <li>
      <Link to="/contact-us">Contact</Link>
    </li>
    <li style={{ width: 350 }}>
      <div
        className="Navigation__Search-Icon-Container"
        role="button"
        name="search"
        tabIndex={0}
        onClick={() => toggleSearchBar()}
        onKeyDown={() => toggleSearchBar()}
      >
        <i className="icon-search Navigation__Search-Icon" />
      </div>
    </li>
  </EvenList>
);

NavigationLinks.propTypes = {
  toggleMegaMenu: PropTypes.func.isRequired,
  isSticky: PropTypes.bool.isRequired,
};

export default NavigationLinks;
