import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: #939598;
  color: #ffffff;
  padding: 40px 0;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }

  @media print {
    display: none;
  }

  .Footer__Links {
    list-style-type: none;
    padding: 0;
  };

  .Footer__Links a {
    color: #ffffff;
  }

  .Footer__Links--Main li {
    padding-bottom: 8px;

    a {
      font-size: 18px;
    }

    &:last-child {
      padding-bottom: 16px;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 5px;
    }
  }

  .Footer__Divider {
    height: 2px;
    background-color: #ffffff;
    border: none;
  }

  .Footer__Links--Inline {
    @media screen and (max-width: 768px) {
      margin-bottom: 40px;
    }
  }

  .Footer__Links--Inline li {
    display: inline-block;
    margin-right: 20px;
    }
  }

  .Footer__SmallPrint--Privacy {
    display: block;
    margin-top: 12px;
    margin-bottom: 32px;
    font-weight: 300;
  }

  .Footer__SmallPrint--Link {
    color: white;
    text-decoration: underline;

    &:hover {
      opacity: 0.75;
    }
  }

  .Footer__SmallPrint--Copyright {
    float: right;
    margin-top: 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .Footer__Contact-Address {
    margin-bottom: .5rem;
  }

  .Footer__Contact-Tel {
    color: #ffffff;
    margin-bottom: 2rem;
    display: block;
  }

  .Footer__Icon {
    font-size: 1.75rem;
  }

  .InputGroup__Container,
  .Footer__SmallPrint--Privacy {
    width: 85%;
  }

  .InputField__Input,
  .InputField__Input:focus {
    border: 0;
  }
`;

const FooterWrap = ({ children }) => <StyledFooter>{children}</StyledFooter>;

FooterWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FooterWrap;
