import styled from 'styled-components';

const NavigationBar = styled.div`
  position: relative;
  text-align: right;

  @media print {
    display: none;
  }

  @media screen and (min-width: 1025px) {
    background-color: #f58021;
    background-image: linear-gradient(
      to right,
      #f58021 5%,
      rgba(245, 128, 33, 0.5) 100%
    );
    text-align: left;
    top: 3px;
    padding: 18px 0;
    position: relative;

    .NavigationBar__MobileToggle {
      display: none;
    }

    &::before {
      background: #f58021;
      content: '';
      display: block;
      height: 100%;
      left: -20px;
      position: absolute;
      width: 40px;
      z-index: 1;
      top: 0;
      transform: skew(-29deg);
      overflow: hidden;
    }
  }
`;

export default NavigationBar;
