import styled from 'styled-components';

export default styled.div`
  a {
    color: #00254a;

    &:hover {
      color: #ffffff;
    }
  }
`;
