import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Cell, Grid } from '../../global/grid';
import MegaMenuSubServices from './MegaMenuSubServices';

const MegaMenuContent = ({ data }) => {
  const [activeId, setActiveId] = useState(0);
  const { services, subServices } = data;

  const featuredPost = useStaticQuery(graphql`
    {
      wpPost {
        excerpt
        slug
        articleAcf {
          featuredImage {
            sourceUrl
          }
        }
      }
    }
  `);

  return (
    <div className="MegaMenu__Inner">
      <Grid gutter={48}>
        <Cell hideCell={{ desktop: true, widescreen: true }} />
        <Cell
          cellSize={{ desktop: 4, widescreen: 4 }}
          hideCell={{ phone: true, tablet: true }}
        >
          <h2 className="MegaMenu__Heading">Latest news</h2>
          <>
            <img
              src={featuredPost.wpPost.articleAcf.featuredImage.sourceUrl}
              alt="Featured Article"
              style={{
                marginBottom: 16,
                height: 300,
                width: '100%',
                objectFit: 'cover',
              }}
            />
            <div
              className="MegaMenu__Blurb"
              dangerouslySetInnerHTML={{
                __html: featuredPost.wpPost.excerpt,
              }}
            />
            <Link to={`/news/${featuredPost.wpPost.slug}`}>Read more</Link>
          </>
        </Cell>

        <Cell cellSize={{ desktop: 4, widescreen: 4 }}>
          <h2 className="MegaMenu__Heading MegaMenu__Heading--Mobile">
            Our services
          </h2>

          <ul className="MegaMenu__List MegaMenu__Links--Mobile">
            {services.edges.map(edge => (
              <li key={edge.node.id} data-parent={edge.node.id}>
                <Link
                  to={`/our-services/${edge.node.slug}`}
                  dangerouslySetInnerHTML={{ __html: edge.node.title }}
                  onFocus={() => {
                    if (window.innerWidth > 768) {
                      setActiveId(edge.node.id);
                    }
                  }}
                  onMouseOver={() => {
                    if (window.innerWidth > 768) {
                      setActiveId(edge.node.id);
                    }
                  }}
                />
              </li>
            ))}
          </ul>
        </Cell>

        <Cell cellSize={{ desktop: 4, widescreen: 4 }}>
          <MegaMenuSubServices
            parentId={activeId}
            services={subServices.edges}
          />

          <ul className="MegaMenu__List MegaMenu__List--Mobile MegaMenu__Links--Mobile">
            <li>
              <Link to="/covid-19">Covid-19</Link>
            </li>
            <li>
              <Link to="/who-we-are">Who we are</Link>
            </li>
            <li>
              <Link to="/news">News &amp; insights</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
          </ul>
        </Cell>
      </Grid>
    </div>
  );
};

export default MegaMenuContent;
