import React from 'react';
import PropTypes from 'prop-types';

import StyledCell from './styles/StyledCell';

const Cell = ({
  children,
  cellAlign,
  cellOrder,
  cellOffset,
  cellSize,
  hideCell,
}) => (
  <StyledCell
    className="Cell"
    cellSize={cellSize}
    cellOrder={cellOrder}
    cellOffset={cellOffset}
    cellAlign={cellAlign}
    hideCell={hideCell}
  >
    {children}
  </StyledCell>
);

Cell.defaultProps = {
  cellAlign: null,
  cellOrder: null,
  cellOffset: null,
  cellSize: {
    phone: 12,
    tablet: 12,
    desktop: 12,
    widescreen: 12,
  },
  hideCell: {
    phone: false,
    tablet: false,
    desktop: false,
    widescreen: false,
  },
};

Cell.propTypes = {
  children: PropTypes.node.isRequired,
  cellAlign: PropTypes.string,
  cellOrder: PropTypes.shape({
    phone: PropTypes.number,
    tablet: PropTypes.number,
    desktop: PropTypes.number,
    widescreen: PropTypes.number,
  }),
  cellOffset: PropTypes.shape({
    phone: PropTypes.number,
    tablet: PropTypes.number,
    desktop: PropTypes.number,
    widescreen: PropTypes.number,
  }),
  cellSize: PropTypes.shape({
    phone: PropTypes.number,
    tablet: PropTypes.number,
    desktop: PropTypes.number,
    widescreen: PropTypes.number,
  }),
  hideCell: PropTypes.shape({
    phone: PropTypes.bool,
    tablet: PropTypes.bool,
    desktop: PropTypes.bool,
    widescreen: PropTypes.bool,
  }),
};

export default Cell;
