import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

import BlockList from '../../common/list/BlockList';

import StyledProfessionalAccreditations from './StyledProfessionalAccreditations';

const ProfessionalAccreditations = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpProfAccreditation {
          edges {
            node {
              title
              id
              link
              customFields {
                members {
                  ... on WpStaffMember {
                    id
                    title
                    slug
                  }
                }
                logo {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { edges } = data.allWpProfAccreditation;

      return (
        <StyledProfessionalAccreditations>
          {edges.map((edge) => (
            <div key={edge.node.id}>
              <div className="ProfessionalAccreditations__Entry">
                <div className="ProfessionalAccreditations__Logo">
                  <img
                    alt={edge.node.title}
                    src={edge.node.customFields.logo.sourceUrl}
                    style={{
                      height: 54,
                      marginBottom: 12,
                    }}
                  />
                </div>

                {edge.node.customFields.members && (
                  <div className="ProfessionalAccreditations__Member">
                    <BlockList>
                      {edge.node.customFields.members.map((member) => (
                        <li key={member.id}>
                          <Link to={`/who-we-are/our-people/${member.slug}`}>
                            {member.title}
                          </Link>
                        </li>
                      ))}
                    </BlockList>
                  </div>
                )}
              </div>
            </div>
          ))}
        </StyledProfessionalAccreditations>
      );
    }}
  />
);

export default ProfessionalAccreditations;
