import styled from 'styled-components';

export default styled.div`
  text-align: left;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  padding: 40px 8px;
  z-index: 10;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 768px) {
    padding: 80px 54px 115px 40px;
  }

  .SearchComponent__Exit {
    margin-bottom: 20px;
    margin-top: -20px;
    float: right;
    outline: none;
    font-size: 28px;
    border: none;
    cursor: pointer;
    background-color: #ffffff;

    @media screen and (min-width: 768px) {
      margin-bottom: 40px;
      margin-right: -20px;
      margin-top: -40px;
    }
  }
`;
