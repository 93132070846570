import styled from 'styled-components';

const StyledGrid = styled.div`
  align-items: ${props => props.alignItems};
  display: flex;
  flex-flow: row wrap;
  margin-left: -${props => props.gutter}px;

  ${({ spacing }) => spacing && `padding: ${spacing}px`};

  .Cell {
    padding-left: ${props => props.gutter}px;

    @media print {
      display: inline-block;
    }
  }
`;

export default StyledGrid;
