/**
 * Configuration for styled components
 * In an attempt to ensure settings are central, please ensure
 * global settings are placed here and referenced accordingly.
 */

/**
 * Viewport Sizes, to be used with Media Queries.
 */
export const viewports = {
  tablet: '768px',
  desktop: '1024px',
  widescreen: '1200px',
};

/**
 * Columns, the number of columns in the grid for a given device.
 */
export const columns = {
  phone: 6,
  tablet: 12,
  desktop: 12,
  widescreen: 12,
};
