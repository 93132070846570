import styled from 'styled-components';

export default styled.div`
  font-weight: 400;
  margin-bottom: 52px;

  .SearchResults__Heading {
    margin-bottom: 30px;
    color: #f58021;
    font-size: 26px;
    font-weight: lighter;
    font-family: 'henderson-sans-basic';
  }
  }

  .SearchResults__Subheading {
    color: #00254a;
    margin-bottom: 12px;
  }

  .SearchResults__Content {
    color: rgba(112, 112, 112, 0.5);
    font-style: italic;
    margin-bottom: 24px;
    max-width: 750px;
  }
`;
