import styled from 'styled-components';

export default styled.form`
  margin-bottom: 16px;
  position: relative;

  label {
    width: 100%;

    &:hover {
      cursor: text;
    }

    &:after {
      content: '';
      padding-top: 12px;
      display: block;
      border-bottom: 1px solid #d9d9d9;
      width: 100%;
    }
  }

  .SearchComponent__Icon {
    display: inline-block;
    padding-left: 4px;
    color: #00254a;
    font-size: 20px;
    width: 28px;

    @media screen and (min-width: 768px) {
      font-size: 22px;
      width: 40px;
    }
  }

  .SearchComponent__Spinner {
    position: absolute;
    right: 0;
    bottom: 12px;

    .spinner {
      margin: 0;
      bottom: 12px;
      height: 24px;
      width: 24px;

      @media screen and (min-width: 768px) {
        height: 32px;
        width: 32px;
      }
    }
  }

  .SearchComponent__Term {
    position: relative;
    display: inline-block;
    border: none;
    color: #6d6e71;
    font-size: 18px;
    font-weight: 300;
    width: calc(100% - 28px);
    &:focus {
      outline: none;
    }

    @media screen and (min-width: 768px) {
      width: calc(100% - 80px);
      font-size: 24px;
    }
  }
`;
