/**
 * A list that displays items as inline items and the space between
 * each list item is evenly separated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledList = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    text-align: ${props => props.alignment};
    margin-right: ${props => props.spaceValue}px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const RightList = ({
  alignment,
  children,
  spaceValue,
}) => <StyledList alignment={alignment} spaceValue={spaceValue}>{children}</StyledList>;

RightList.defaultProps = {
  alignment: 'left',
  spaceValue: 0,
};

RightList.propTypes = {
  alignment: PropTypes.string,
  children: PropTypes.node.isRequired,
  spaceValue: PropTypes.number,
};

export default RightList;
