import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const theme = {
  default: {
    fontSize: '16px',
  },

  contactForm: {
    fontSize: '20px',
    width: '100%',
    maxWidth: '272px',
  },
};

const StyledButton = styled.div`
  button {
    background-color: #00254A;
    border: 1px solid #00254A;
    color: #FFFFFF;
    cursor: pointer;
    font-size: ${props => theme[props.variation].fontSize};
    text-transform: uppercase;
    padding: .385rem .75rem;
    line-height: 1.5;
    text-align: center;
    appearance: none;
    width: ${props => theme[props.variation].width};

    @media screen and (min-width: 401px) {
      max-width: ${props => theme[props.variation].maxWidth};
    }
  }
`;

const Button = ({
  label,
  onClick,
  variation,
}) => (
  <StyledButton variation={variation}>
    <button onClick={onClick} type="submit">
      {label}
    </button>
  </StyledButton>
);

Button.defaultProps = {
  onClick: null,
  variation: 'default',
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variation: PropTypes.string,
};

export default Button;
