import styled from 'styled-components';

const NavigationLinkWrap = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }

  ul li a,
  ul li .Navigation__Faux-Link {
    color: #ffffff;
    cursor: pointer;
    font-family: 'henderson-sans-basic', sans-serif;
    text-transform: uppercase;
    position: relative;
    z-index: 5;
    outline: none;

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      font-size: 14px;
    }
  }
`;

export default NavigationLinkWrap;
