import React from 'react';
import { Link } from 'gatsby';

import getSubServices from '../../../helpers/data/sub-services';

const MegaMenuSubServices = ({ services, parentId }) => {
  const megaMenuChildPages = getSubServices(services, parentId);

  return (
    <ul className="MegaMenu__List MegaMenu__List--Small">
      {megaMenuChildPages.law && megaMenuChildPages.property
        ? Object.keys(megaMenuChildPages)
            .reverse()
            .map(type => [
              <li key={type} className="MegaMenu__ListHeading">
                <b>
                  {type === 'law'
                    ? 'Company commercial'
                    : 'Commercial property'}
                </b>
              </li>,
              megaMenuChildPages[type].map(page => (
                <li key={page.node.id}>
                  <Link
                    to={`/our-services/${page.node.wpParent.node.slug}/${page.node.slug}`}
                    dangerouslySetInnerHTML={{ __html: page.node.title }}
                  />
                </li>
              )),
            ])
        : megaMenuChildPages.length > 0 &&
          megaMenuChildPages.map(page => (
            <li key={page.node.id}>
              <Link
                to={`/our-services/${page.node.wpParent.node.slug}/${page.node.slug}`}
                dangerouslySetInnerHTML={{ __html: page.node.title }}
              />
            </li>
          ))}
    </ul>
  );
};

MegaMenuSubServices.defaultProps = {
  parentId: 0,
  pages: [],
};

export default MegaMenuSubServices;
